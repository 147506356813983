export type Machine = {
  id: string;
};

export type MachineList = Array<Machine>;

export default function getMachines(): MachineList {
  return [
    {
      id: "munckhof_trailed_sprayer",
    },
    {
      id: "munckhof_root_pruner",
    },
    {
      id: "boreko_root_pruner",
    },
    {
      id: "kwh_root_pruner",
    },
  ];
}
