import { bbox } from "@turf/turf";
import {
  Feature,
  MultiPolygon as GeoJsonMultiPolygon,
  Polygon as GeoJsonPolygon,
} from "geojson";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  GeolocateControl,
  Layer,
  LngLatBoundsLike,
  Map,
  MapRef,
  NavigationControl,
  ScaleControl,
  Source,
} from "react-map-gl/maplibre";
import { getUrl, mapTransformRequest } from "../../API/AureaApi";
import { FieldProperties, FieldsGeoJSON, Tiling } from "../../API/fields";
import { mapStyle } from "../Map";
import Popup from "../Map/Popup";
import useLayerEvent from "../Map/useLayerEvent";
import "./FieldMap.module.css";
import styles from "./FieldMap.module.css";

export type FieldFeature = Feature<
  GeoJsonMultiPolygon | GeoJsonPolygon,
  FieldProperties
>;

export type FieldMapProps = {
  geojson: FieldsGeoJSON;
  selectedFieldId?: number;
  tiling?: Tiling | null;
  onClick: (feature: FieldFeature) => void;
  children?: React.ReactNode;
  events?: React.UIEvent;
};

export default function FieldMap({
  geojson,
  selectedFieldId,
  onClick,
  children,
  tiling,
}: FieldMapProps): React.ReactNode {
  const ref = useRef<MapRef | null>(null);

  const selectedField = geojson.features.find(
    (field) => field.properties.id === selectedFieldId,
  );

  const bounds: LngLatBoundsLike = geojson.features.length
    ? (bbox(selectedField || geojson) as LngLatBoundsLike)
    : [-10, 33, 30, 60];

  useEffect(() => {
    if (ref.current && selectedField) {
      ref.current.fitBounds(bbox(selectedField) as LngLatBoundsLike, {
        maxDuration: 1000,
        maxZoom: 17,
        duration: 500,
      });
    }
  }, [selectedFieldId, ref]);

  return (
    <div className={styles.fieldOverview}>
      <Map
        mapStyle={mapStyle}
        transformRequest={mapTransformRequest}
        initialViewState={{
          bounds,
          fitBoundsOptions: {
            maxZoom: 17,
          },
        }}
        ref={ref}
        maxZoom={25}
        attributionControl={false}
      >
        <FieldLayer
          selectedFieldId={selectedFieldId}
          onClick={onClick}
          fields={geojson}
        />
        <PhotoLayer photo={tiling} />
        <ScaleControl position="bottom-right" />
        <NavigationControl position="bottom-right" />
        <GeolocateControl position="bottom-right" trackUserLocation={true} />
        {children}
      </Map>
    </div>
  );
}

type PhotoLayerProps = { photo: Tiling | null | undefined };
function PhotoLayer({ photo }: PhotoLayerProps) {
  return (
    photo && (
      <Source
        key={photo.id}
        type="raster"
        tiles={[getUrl(`/v1/tiling/${photo.id}/tile/{z}/{x}/{y}`)]}
        scheme="tms"
        tileSize={256}
        maxzoom={photo.max_zoom_level || 22}
      >
        <Layer type="raster" beforeId="tiling" />
      </Source>
    )
  );
}

type FieldProps = {
  fields: FieldsGeoJSON;
  selectedFieldId?: number;
  onClick: FieldMapProps["onClick"];
};

function FieldLayer({
  fields,
  selectedFieldId,
  onClick,
}: FieldProps): React.ReactNode {
  const rootStyle = getComputedStyle(document.documentElement);

  useLayerEvent(
    "click",
    "fields-fill",
    /* istanbul ignore next */ (event) => {
      if (event?.features?.length) {
        onClick(event.features[0] as unknown as FieldFeature);
      }
    },
  );

  return (
    <>
      <Source type="geojson" data={fields}>
        <Layer
          id="fields-fill"
          type="fill"
          paint={{
            "fill-color": rootStyle.getPropertyValue("--aurea-normal"),
            "fill-opacity": 0.3,
          }}
          filter={["!=", ["get", "id"], selectedFieldId || 0]}
          beforeId="field"
        />
        <Layer
          type="line"
          paint={{
            "line-color": [
              "case",
              ["==", ["get", "id"], selectedFieldId || 0],
              rootStyle.getPropertyValue("--aurea-highlight"),
              rootStyle.getPropertyValue("--aurea-normal"),
            ],
            "line-width": 3,
          }}
          beforeId="fields-fill"
        />
      </Source>
      <Popup layer="fields-fill" component={FieldInformation} />
    </>
  );
}

function FieldInformation({
  properties,
}: {
  properties: FieldProperties;
}): React.ReactNode {
  const { t } = useTranslation();

  return (
    <>
      <strong className={styles.fieldItemTitle}>{properties.name}</strong>
      <span className={styles.fixedWidthArea}>
        {t("text.{{area}}.ha", {
          area: properties.area_ha.toFixed(2),
        })}
      </span>
      <span className={styles.fixedVariety}>{properties.variety}</span>
    </>
  );
}
